import {css} from "lit";

export const sbkMetaNavItemStyle = css`
    
    div {
        display: block;
        height: 100%;
    }
  
  * {
    box-sizing: border-box;
  }
  
  .border-right {
    position: relative;
    margin-right: var(--sbkTopNavBarSpacingLargeScreenGap);
  }
    .border-right:after {
      content: "";
      position: absolute;
      right: calc(var(--sbkTopNavBarSpacingLargeScreenGap) * -1);
      bottom: calc((var(--sbkTabSpacing2xs) + var(--sbkTopNavBarSizingDividerHeight)) / 2);
      background: var(--sbkTopNavBarFgColorOnLightEnabled);
      height: var(--sbkTopNavBarSizingDividerHeight);
      width: var(--sbkTopNavBarSizingDividerWidth);
    }

    ::slotted(*) {
        height: 100%;
    }
`;
