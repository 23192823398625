import { customElement } from 'lit/decorators.js';
import { sbkMetaNavItemsStyle } from './meta-nav-items.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-meta-nav-items')
export class SbkMetaNavItems extends LitElement {
    static get styles() {
        return sbkMetaNavItemsStyle;
    }

    render() {
        return html`
            <ul>
                <slot></slot>
            </ul>
        `;
    }
}
