import {customElement} from 'lit/decorators.js';
import {sbkMetaNavItemStyle} from './meta-nav-item.styles';
import {html, LitElement} from 'lit';


@customElement('sbk-meta-nav-item')
export class SbkMetaNavItem extends LitElement {

    static get styles() {
        return sbkMetaNavItemStyle;
    }

    connectedCallback() {
        super.connectedCallback();
        this.setAttribute('role', 'listitem');
    }

    render() {
        return html`
            <div>
                <slot></slot>
            </div>
        `;
    }
}
